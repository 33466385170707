// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import constants from '@src/constants'
const {host, mekan} = constants

// ** Axios Imports
const axios = require('../utility/axios').default

export const getUserPermissions = createAsyncThunk('appUserPermissions/getUserPermissions', async (vars) => {

   try {
    const {data} = await axios.axiosPost({
        uyeid: vars.uyeid
    }, '/getUserPermissions')
    //console.log({data});
    if (data.error === true) {
        return data.data
    } else {
        return data.data
    }
   } catch (error) {
    console.log(error)
   }

})

export const appUserPermissions = createSlice({
    name: 'appUserPermissions',
    reducers: {},
    initialState: {
        data: []
    },
    extraReducers: builder => {
        builder
            .addCase(getUserPermissions.fulfilled, (state, action) => {
                state.data = action.payload
            })
    }
})

export default appUserPermissions.reducer
