// ** Redux Imports
import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import constants from '@src/constants'
import {useEffect} from 'react';
import toast from 'react-hot-toast';

const {host, mekan} = constants

// ** Axios Imports
const axios = require('../../../../utility/axios').default;
export const userList = createAsyncThunk('appKullaniciListesi/userList', async (vars) => {


    const {data} = await axios.axiosPost({
        page: vars.page,
        resultsPerPage: vars.perPage,
        search: vars.q,
        filter1: vars.filter1 ? vars.filter1 : '0',
        filter2: vars.filter2 ? vars.filter2 : '0',
        filter3: vars.filter3 ? vars.filter3 : '0',
        mekan: mekan,
        sortColumn: vars.sortColumn,
        sortType: vars.sortType
    }, '/userManagement/userList')


    if (data.error === true) {
        return data.data


    } else {
        return data.data
    }

})

export const getUser = createAsyncThunk('appKullaniciListesi/allInfo', async (vars) => {
    const {data} = await axios.axiosPost({
        uyeid: vars.uyeid,
        mekan: mekan
    }, '/userDetail/allInfo')
    if (data?.error === true) {
        if (data?.message)
            toast.error(data?.message);
    } else {
        return data.data
    }
})

export const userStatistics = createAsyncThunk('appKullaniciListesi/userStatistics', async (vars) => {

    const postData = {
        uyeid: vars.uyeid,
        mekan: mekan
    }

    const {data} = await axios.axiosPost(postData, '/userDetail/userStatistics');

    return data.data;

})

export const appUsersSlice = createSlice({
    name: 'appKullaniciListesi',
    initialState: {
        userStatistics: {},
    },
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(userList.fulfilled, (state, action) => {
                state.data = action.payload
            })
            .addCase(getUser.fulfilled, (state, action) => {
                state.data = action.payload
            })
            .addCase(userStatistics.fulfilled, (state, action) => {
                state.userStatistics = action.payload
            })
    }
})

export default appUsersSlice.reducer
