import { Navigate } from "react-router-dom"

const CryptoJS = require('crypto-js')
import constants from "@src/constants"
import axios from "./axios"

const { uploadJwtKey } = constants


const money = (data) => {
    const nf = new Intl.NumberFormat("tr-TR", {
        maximumFractionDigits: 2
    })
    return nf.format(data)
}
const convertMoneyToSymbol = (currency) => {
    switch (currency) {
        case "TRY":
            return " ₺"
        case "USD":
            return " $"
        case "EUR":
            return " €"
        case "GBP":
            return " £"
        case "JPY":
            return " ¥"
        case "RUB":
            return " ₽"
        default:
            return " ₺"
    }
}
const getPlaceWithAPIUrl = (place) => {
    switch (place) {
        case "OM":
            return "https://om.oyuncumarket.com"
        case "OY":
            return "https://oy.oyunyardim.com"
        case "KSO":
            return "https://ekn.kabasakalonline.com/"
        case "EE":
            return "https://ee.enucuzepin.com.tr/"
        case "PB":
            return "https://pnb.paynbuy.com/"
    }
}
const getAllActivePlace = () => {
    return ['OM', 'OY', 'KSO'/*,'EE','PB'*/]
}
const moneySymbol = async (element) => { // para birimi sf den çevrim yapar
    if (element?.sf > 0) {
        return `${money(element?.sf)} TL`
    } else if (element?.EURO > 0) {
        return `${money(element?.EURO)} EURO`
    } else if (element?.GBP > 0) {
        return `${money(element?.GBP)} GBP`
    } else if (element?.JPY > 0) {
        return `${money(element?.JPY)} JPY`
    } else if (element?.RUB > 0) {
        return `${money(element?.RUB)} RUB`
    } else if (element?.USD > 0) {
        return `${money(element?.USD)} USD`
    }
}

function seoUrl(name) {
    name = name.replace(/(?!%22)[!@#$%^&*\(\)]/g, "");
    name = name.replace(/™/g, "");
    name = name.replace(/\|/g, "");
    name = name.replace(/ /g, "-");
    name = name.replace(/İ/g, "i");
    name = name.replace(/I/g, "i");
    name = name.replace(/ı/g, "i");
    name = name.replace(/ğ/g, "g");
    name = name.replace(/Ğ/g, "g");
    name = name.replace(/ü/g, "u");
    name = name.replace(/Ü/g, "u");
    name = name.replace(/ş/g, "s");
    name = name.replace(/Ş/g, "s");
    name = name.replace(/ö/g, "o");
    name = name.replace(/Ö/g, "o");
    name = name.replace(/ç/g, "c");
    name = name.replace(/Ç/g, "c");
    name = name.replace(/\//g, "-");
    name = name.replace(/--/g, "-");
    name = name.replace(/---/g, "-");
    name = name.replace(/----/g, "-");
    name = name.replace(/-----/g, "-");
    name = name.replace(/</g, "");
    name = name.replace(/>/g, "");
    name = name.replace(/"/g, "");
    name = name.replace(/é/g, "");
    name = name.replace(/’/, "");
    name = name.replace(/£/, "");
    name = name.replace(/\+/g, "");
    name = name.replace(/½/g, "");
    name = name.replace(/{/g, "");
    name = name.replace(/]/g, "");
    name = name.replace(/=/g, "");
    name = name.replace(/}/g, "");
    name = name.replace(/€/g, "");
    name = name.replace(/~/g, "");
    name = name.replace(/æ/g, "");
    name = name.replace(/ß/g, "");
    name = name.replace(/;/g, "");
    name = name.replace(/,/g, "");
    name = name.replace(/`/g, "");
    name = name.replace(/|/g, "");
    name = name.replace(/\./g, "");
    name = name.replace(/:/g, "");
    name = name.replace(/–/g, "-");
    name = name.replace(/—/g, "-");
    name = name.replace(/—-/g, "-");
    name = name.replace(/—-/g, "-");
    return name.toLowerCase();
}

const logOut = async () => {

    try {
        const { data } = await axios.axiosPost({}, "/logout")
    } catch (error) {
        console.log(error)
    } finally {
        localStorage.clear()
        window.location.href = "/login"
    }

}

const jtwEncryption = (data) => {
    const ciphertext = CryptoJS.AES.encrypt(JSON.stringify(data), uploadJwtKey).toString()
    return ciphertext
}

const bilgiKapa = (text) => {
    const crypt = CryptoJS.AES.encrypt(text, 'VolkanOyuncuOrtiMarketKaba...123', { iv: '1234567890123412' });
    return crypt.toString()
}

const bilgiAc = (DataEncrypt) => {
    try {
        const decrypted = CryptoJS.AES.decrypt(DataEncrypt, 'VolkanOyuncuOrtiMarketKaba...123', { iv: '1234567890123412' });
        const decrypted2 = CryptoJS.enc.Utf8.stringify(decrypted);
        if (!decrypted2) return "";
        else return decrypted2
    } catch (error) {
        return DataEncrypt;
    }
}

const replaceAllFileName = (str) => {
    try {
        let newStr = str.toLowerCase()
        newStr = newStr.replace(".gif", "").replace(".jpg", "").replace(".png", "").replace(".jpeg", "").replace(".bmp", "").replace(".svg", "").replace(".webp", "")
        return newStr
    } catch (error) {
        return str
    }
}

const pinAbiModelCodeList = (gameName) => {

    // PinABi Pubg Mobile Model Kodu List
    const pinAbiPubgModelKoduList = [
        "ep313299866",
        "ep237355926",
        "ep349993421",
        "ep299867719",
        "ep275227705",
        "ep242529821",
        "ep367175140",
        "ep291206349",
        "ep349522506"
    ]

    // PinABi Pubg Mobile Model Kodu List Parcali
    const pinAbiPubgModelKoduListParcali = [
        "ep313299866",
        "ep237355926",
        "ep349993421",
        "ep299867719",
        "ep275227705",
        "ep387389957",
        "ep216659804",
        "ep342049149",
        "ep312935730",
        "ep329493541",
        "ep389427038",
        "ep327013110",
        "ep315727798",
        "ep331233228",
        "ep313286436",
        "ep244074814",
        "ep295313414",
        "ep322059425",
        "ep326645052",
        "ep305841297",
        "ep242529821",
        "ep367175140",
        "ep291206349",
        "ep349522506"
    ]

    // PinABi MBL Model Kodu List
    const pinAbiMblKoduList = [
        "ep388884567",
        "ep374261547",
        "ep211742813",
        "ep346115819",
        "ep346528733",
        "ep353681797",
        "ep274439325",
        "ep314396731",
        "ep298565810",
        "ep225929057",
        "ep295612202",
        "ep335975073",
        "ep289308650",
        "ep337502478",
        "ep331872107",
        "ep309745259",
        "ep397336795",
        "ep288158369",
        "ep381968504",
        "ep239409474"
    ]

    return gameName == "pubg" ? pinAbiPubgModelKoduList : gameName == "pubgParcali" ? pinAbiPubgModelKoduListParcali : pinAbiMblKoduList
}

const restartBotModelCodeList = () => {

    const modelCodes = [
        "ep332789097",
        "ep361629251",
        "ep273602726",
        "ep322701579",
        "ep396636385",
        "ep293787001",
        "ep365455970",
        "ep378793222",
        "ep262007686",
        "ep277984948"
    ]

    return modelCodes
}

const orderStatusText = (buyerConfirmation, sellerConfirmation) => {

    let status = "";
    if (buyerConfirmation == 0 && sellerConfirmation == 0) status = "Satıcı Bekleniyor";
    else if (sellerConfirmation == 1 && buyerConfirmation == 0) status = "Alıcı Bekleniyor";
    else if (sellerConfirmation == 1 && buyerConfirmation == 1) status = "Tamamlandı";
    else if (sellerConfirmation == 2 && buyerConfirmation != 2) status = "Satıcı Sorun Bildirdi";
    else if (buyerConfirmation == 2 && sellerConfirmation != 2) status = "Alıcı Sorun Bildirdi";
    else if (sellerConfirmation == 2 && buyerConfirmation == 2) status = "İptal Edildi";
    else if (sellerConfirmation == 3 && buyerConfirmation != 2) status = "İşleme Alındı";
    return status;
}

export { replaceAllFileName, jtwEncryption, money, moneySymbol, seoUrl, logOut, convertMoneyToSymbol, getPlaceWithAPIUrl, getAllActivePlace, bilgiKapa, bilgiAc, pinAbiModelCodeList, orderStatusText, restartBotModelCodeList }