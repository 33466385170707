// ** Redux Imports
import { createSlice, createAsyncThunk, current } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from '../../../../utility/axios'
import toast from 'react-hot-toast'
// import axios from 'axios'
export const getUserProfile = createAsyncThunk('appChat/getTasks', async () => {
  const response = await axios.axiosGet('/apps/chat/users/profile-user')
  return response.data
})

export const getChatContacts = createAsyncThunk('appChat/getChatContacts', async (props) => {
 try {
  const response = await axios.axiosPost({
    mekan:props.mekan,
    page:+props.page,
    resultsPerPage: +props.perPage,
    search:props.searchTerm,
    uyeid: props.uyeid
  }, "/userDetail/sellerMessages")
  if (response.data.error == true) {
    toast.error(response.data.message)
  } 
  return {
    data:response?.data?.data || [],
    total:response?.data?.totalItems || 0
  } 

  // const response = await axios.get('/apps/chat/chats-and-contacts')
  
  // console.log(response.data);
  // return response.data
 } catch (error) {
  console.log(error)
 }
})

export const selectChat = createAsyncThunk('appChat/selectChat', async (props) => {
  // const response = await axios.get('/apps/chat/get-chat', { id })
  // await dispatch(getChatContacts())
  // return response.data
  // try {
  //   const {data} = await axios.axiosPost({
  //     talepId:+props.id,
  //     mekan:props.mekan,
  //   },"/userDetail/MessageDetail")
  
  //   console.log({data2:data});
  //   return data.data ? data.data : []
  // } catch (error) {
  //   console.log(error);
  // }


  return {
    id:props.id,
    mekan:props.mekan,
    gonderenid:props.gonderenid,
    aliciid:props.aliciid,
    soran_online:props.soran_online,
    soran_resim:props.soran_resim,
    soran_id:props.soran_id,
    soran_isim:props.soran_isim,
    soran_soyisim:props.soran_soyisim,
    okundu: props.okundu,
    magaza_isim: props.magaza_isim,
    magaza_soyisim: props.magaza_soyisim,
    magaza_kullaniciadi: props.mesaj_alici_kullaniciadi,
    magaza_resim: props.magaza_resim
  }
})

export const sendMsg = createAsyncThunk('appChat/sendMsg', async (obj, { dispatch }) => {
  // const response = await axios.post('/apps/chat/send-msg', { obj })
  // await dispatch(selectChat(obj.contact.id))
  // return response.data
})

export const appChatSlice = createSlice({
  name: 'appChat',
  initialState: {
    chats: [],
    total:0,
    contacts: [],
    userProfile: {},
    selectedUser: {}
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getUserProfile.fulfilled, (state, action) => {
        state.userProfile = action.payload
      })
      .addCase(getChatContacts.fulfilled, (state, action) => {

        // unique array
        const mergeArray = [...state.chats, ...action.payload.data]

        const uniqueArray = mergeArray.filter((thing, index) => {
          const _thing = JSON.stringify(thing)
          return (
            index === mergeArray.findIndex(obj => {
              return JSON.stringify(obj) === _thing
            })
          )
        })

        state.chats = uniqueArray
        state.total = action.payload.total
        // state.contacts = action.payload.contacts
      })
      .addCase(selectChat.fulfilled, (state, action) => {
        state.selectedUser = action.payload
      })
  }
})

export default appChatSlice.reducer