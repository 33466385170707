
import constants from '@src/constants'
import axios from 'axios'
import { logOut, jtwEncryption } from './function'
const {host,hostProtectApi, hostOm, hostOy, hostEe, hostPnb, hostKso, mekan, omToken, hostUpload} = constants
const axiosPost = async (body, path,protect = false) => {
   try {
    const data = await axios.post((protect ? hostProtectApi : host) + path, body, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            "Content-Type": "application/json",
            Accept: "application/json",
            'Origin':'http://localhost:3000'
        }
    })
    if (data?.status === 401) {
        logOut()
        return false
    }
    return data
   } catch (error) {
    return error?.response
   }
}

const axiosPostFormData = async (body, path) => {
    try {
        const data = await axios.post(host + path, body, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
                'Content-Type':"multipart/form-data",
                'Origin':'http://localhost:3000'
            }
        })
        if (data?.status === 401) {
            logOut()
            return false
        }
        return data
      } catch (error) {
        return error?.response
      }
}
const axiosPostFormDataWithUrl = async (body, url, path) => {
    try {
        const data = await axios.post(url + path, body, {
            headers: {
                Authorization: `Bearer ${omToken}`,
                'Content-Type':"multipart/form-data",
                'Origin':'http://localhost:3000'
            }
        })
        if (data?.status === 401) {
            logOut()
            return false
        }
        return data
      } catch (error) {
        return error?.response
      }
}

const axiosPostFormDataUpload = async (body, path, mekan, type) => {
    try {
        const Jwt = jtwEncryption({
            id:Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15),
            type:"upload",
            date: new Date().getTime()
        })
        const data = await axios.post(`${hostUpload + path}?mekan=${mekan}&type=${type}&q=${Jwt}`, body, {
            headers: {
                Authorization: `Bearer ${omToken}`,
                'Content-Type':"multipart/form-data",
                'Origin':'http://localhost:3000'
            }
        })
        
        if (data?.status === 401) {
            logOut()
            return false
        }
        return data
      } catch (error) {
        return error?.response
      }
}
const axiosPostFormDataOm = async (body, path) => {
  try {
    const data = await axios.post(hostOm + path, body, {
        headers: {
            Authorization: `Bearer ${omToken}`,
            "Content-Type": "multipart/form-data",
            'Origin':'http://localhost:3000'
        }
    })
    if (data?.status === 401) {
        logOut()
        return false
    }
    return data
  } catch (error) {
    const resp = error?.response
    const {data} = resp
    console.log(error)
    
    return error?.response
  }
}
const axiosGet = async (body, path,protect = false) => {
   try {
    const data = await axios.get(`${(protect ? hostProtectApi : host )+ path}?${body.toString()}`, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Origin':'http://localhost:3000'
        }
    })
    if (data?.status === 401) {
        logOut()
        return false
    }
    return data
   } catch (error) {
    console.log(error)
   }
}

const axiosGetWithPlace = async(body, path, place) => {
    try {
     let url = ""
     let token = ""
     switch (place) {
         case "OM":
             url = hostOm    
             token = omToken    
             break
         case "OY":
             url = hostOy
             token = omToken
             break
         case "EE":
             url = hostEe
             token = omToken
             break
         case "PNB":
             url = hostPnb 
             token = omToken
             break
         case "KSO":
             url = hostKso
             token = omToken
             break          
         default:
             url = hostOm
             token = omToken    
             break
     } 
     const data = await axios.get(`${url + path}?${body.toString()}`, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Origin':'http://localhost:3000'
        }
    })
    if (data?.status === 401) {
        logOut()
        return false
    }
     return data
    } catch (error) {
     console.log(error)
    }
 }

const axiosPostWithPlace = async(body, path, place) => {
   try {
    let url = ""
    let token = ""
    switch (place) {
        case "OM":
            url = hostOm    
            token = omToken    
            break
        case "OY":
            url = hostOy
            token = omToken
            break
        case "EE":
            url = hostEe
            token = omToken
            break
        case "PNB":
            url = hostPnb 
            token = omToken
            break
        case "KSO":
            url = hostKso
            token = omToken
            break          
        default:
            url = hostOm
            token = omToken    
            break
    } 
    const data = await axios.post(url + path, body, {
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
            'Origin':'http://localhost:3000'
        }
    })
    if (data?.status === 401) {
        logOut()
        return false
    }
    return data
   } catch (error) {
    console.log(error)
   }
}


export default {
    axiosGetWithPlace,
    axiosPostFormDataUpload,
    axiosPostFormDataWithUrl,
    axiosPost,
    axiosGet,
    axiosPostFormData,
    axiosPostWithPlace,
    axiosPostFormDataOm
}