// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import constants from '@src/constants'
const {host} = constants

// ** Axios Imports
import axios from 'axios'

export const categoryAdd = createAsyncThunk('category/categoryAdd', async (wizardData) => {

    return wizardData

})

export const appCategory = createSlice({
    name: 'appCategory',
    initialState: {},
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(categoryAdd.fulfilled, (state, action) => {
                state.data = action.payload
            })
    }
})

export default appCategory.reducer
