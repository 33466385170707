export default {

    // Production    
    host: 'https://api.ksobilisim.com', 
    hostProtectApi:'https://papi.ksobilisim.com',
    hostOm :  'https://om.oyuncumarket.com',
    hostOy:'https://oy.oyunyardim.com',
    hostKso : 'https://ekn.kabasakalonline.com',
    hostUpload: "https://cdn.ksobilisim.com", 

    // Development
    // host: 'http://localhost:3008',
    // hostProtectApi:'http://localhost:3007',
    // hostOm: 'http://localhost:3005',
    // hostOy: 'http://localhost:3005', 
    // hostKso: 'http://localhost:3005', 
    // hostUpload: "http://localhost:3538",

    // Development
    omToken: "U2FsdGVkX1825Utqj9QR7QuEyiYEhEabDpspoJgy7AX/Dhg6QRGRQfWmbfGDKOiZ2cKAhb2Iw99OVdtQNFE06bps+oOPRwLQyp9OXalbkkEDqBbeXzWYjNnoGjPagNOw5LYdMY4vLxzDrKe+dYx2axmrmrP9eQenj9GY6XRAcFmFKbSUitdJAtU/eqkZzsu2uwPQzt9C35eaas9A4/kv1BwYwSGaWjWc6kOKbgcq5Xc=",
    // host: 'http://192.168.1.39:3008',
    mekan: localStorage.getItem("mekan") || "OM",
    uploadJwtKey: "590f46a8-2d44-473f-a6de-459af80cb463",
    IP_KONTROLU: false,
}