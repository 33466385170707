// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
// import axios from 'axios'
const axios = require('../../../../utility/axios').default;

// export const getTasks = createAsyncThunk('appTodo/getTasks', async params => {
//   const response = await axios.get('/apps/todo/tasks', { params })

//   return {
//     params,
//     data: response.data
//   }
// })


export const getTasks = createAsyncThunk('appTodo/getTasks', async params => {
  const response = await axios.axiosPost(params,'/systemManagement/getAllTodoList')
  return {
    params,
    data: response.data.data
  }
})



export const addTask = createAsyncThunk('appTodo/addTask', async (task, { dispatch, getState }) => {
  // console.log('1')
  const formData = new FormData();
  // console.log('2')
  // const gezici = task.file
  // gezici.forEach((file) => {
  //   console.log('3')
  //   formData.append("uploadedImages", file);
  // });

  formData.append("uploadedImages", task.file);

  // console.log(formData)
 let savedImage = null
//  console.log('4')
  const { data } = await axios.axiosPostFormDataUpload(
    formData,
    "/generalUpload",
    'tg',
    'yt'
  );
  if(data?.error === false){
    savedImage = data?.imagePath ? data?.imagePath : ''; 
  }else{
    console.log(data?.message)
  }

  // console.log(savedImage)

  const response = await axios.axiosPost({
    title: task.title,
    description: task.description,
    importanceLevel: task.tags[0] == 'onemsizdusuk' ? 1 : task.tags[0] == 'dusuk' ? 2 : task.tags[0] == 'orta' ? 3 : task.tags[0] == 'yuksek' ? 4 : task.tags[0] == 'onemliyuksek' ? 5 : 0,
    image : savedImage,
  },'/systemManagement/createNewJobs')
  await dispatch(getTasks(getState().todo.params))
  return response.data
})

export const updateTask = createAsyncThunk('appTodo/updateTask', async (task, { dispatch, getState }) => {
  const response = await axios.axiosPost({
    jobsId : task.id,
    startDate : task.dueDate,
    title : task.title,
    description : task.description,
    importanceLevel : task.tags[0] == 'onemsizdusuk' ? 1 : task.tags[0] == 'dusuk' ? 2 : task.tags[0] == 'orta' ? 3 : task.tags[0] == 'yuksek' ? 4 : task.tags[0] == 'onemliyuksek' ? 5 : 0,
    tags : task.tags,
    jobsPersonId : task.assignee.uyeid

  },'/systemManagement/updateJobs')
  await dispatch(getTasks(getState().todo.params))
  return response.data
})
                       
export const deleteTask = createAsyncThunk('appTodo/deleteTask', async (taskId, { dispatch, getState }) => {
  const response = await axios.delete('/apps/todo/delete-task', { taskId })
  await dispatch(getTasks(getState().todo.params))
  return response.data
})

export const appTodoSlice = createSlice({
  name: 'appTodo',
  initialState: {
    tasks: [],
    selectedTask: {},
    params: {
      filter: '',
      q: '',
      sort: '',
      tag: ''
    }
  },
  reducers: {
    reOrderTasks: (state, action) => {
      state.tasks = action.payload
    },
    selectTask: (state, action) => {
      state.selectedTask = action.payload
    }
  },
  extraReducers: builder => {
    builder.addCase(getTasks.fulfilled, (state, action) => {
      state.tasks = action.payload.data
      state.params = action.payload.params
    })
  }
})



export const { reOrderTasks, selectTask } = appTodoSlice.actions

export default appTodoSlice.reducer
